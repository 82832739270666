export const getTimeString = () => {
    const currentDate = new Date();
    const reopenDate = new Date("2024-09-26");
    if (currentDate < reopenDate) {
        return "Closed until Sept 26";
    }

    const date = currentDate.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
        weekday: "long",
        timeZone: "Australia/Sydney",
    }).split(" ");
    const day = date[0];
    const time = date[1].split(":").map((t) => parseInt(t));
    if (day === "Friday" || day === "Saturday") {
        if (time[0] < 17) {
            return "Open today, 5pm - 9:30pm";
        } else if (time[0] < 22) {
            return "Open today until 9:30pm";
        } else {
            return "Open tomorrow at 5pm";
        }
    } else if (day === "Monday") {
        return "Open tomorrow at 5pm";
    } else {
        if (time[0] < 17) {
            return "Open today, 5pm - 9pm";
        } else if (time[0] < 21 || (time[0] === 21 && time[1] < 30)) {
            return "Open today until 9pm";
        } else if (day === "Sunday") {
            return "Open Tuesday at 5pm";
        } else {
            return "Open tomorrow at 5pm";
        }
    }
};

export const HiddenOnMobile = ({ children }) => (
    <div className='hidden md:block'>{children}</div>
)

export const NavItem = ({ link, text, action, className }) => (
    action ? <button onClick={action} className={`${className ?? ''} uppercase md:hover:underline py-2.5 md:py-0 w-full md:w-auto`}>Book a Table</button> :
        <a href={link} className={`${className ?? ''} uppercase md:hover:underline py-2.5 md:py-0 w-full md:w-auto`}>{text}</a>
)


export const NavDivider = () => (
    <span className='text-center hidden md:block'>•</span>
)
