import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home'
import Menu from './pages/Menu'
import SetMenu from './pages/SetMenu'
import KidsClass from './pages/KidsClass'
import Rick from './pages/Rick'

function App() {
  return (
    <Routes>
      <Route exact path='/' element={<Home />} title='Barbert Pizza Co.' />
      <Route exact path='/menu' element={<Menu />} title='Food & Drink Menu - Barbert Pizza Co.' />
      <Route exact path='/setmenu' element={<SetMenu />} title='Set Menu - Barbert Pizza Co.' />
      <Route exact path='/kidsclass' element={<KidsClass />} title='Kids Classes - Barbert Pizza Co.' />
      <Route exact path='/dQw4w9WgXcQ' element={<Rick />} title='Rick - Barbert Pizza Co.' />
    </Routes>
  );
}

export default App;
