import '../../style.css';
import { useEffect, useState, useRef } from 'react';
import Footer from '../../components/Footer';
import Nav from '../../components/Nav';

const Rick = () => {
    document.title = 'A Surprise - Barbert Pizza Co.';
    const videoId = 'dQw4w9WgXcQ'; // Replace with your actual video ID
    const secretCode = 'RICKROLLED'; // Replace with your secret code
    const playerRef = useRef(null); // Use useRef to store the 'player' variable

    const [showPlay, setShowPlay] = useState(true);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        const createYouTubePlayer = () => {
            playerRef.current = new window.YT.Player('player', {
                height: 390,
                width: window.innerWidth > 768 ? '640' : '100%',
                videoId: videoId,
                events: {
                    onReady: onPlayerReady,
                    onStateChange: onPlayerStateChange,
                },
            });
        };

        const script = document.createElement('script');
        script.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(script);

        script.onload = () => {
            window.YT.ready(() => {
                createYouTubePlayer();
            });
        };
    }, []);

    const onPlayerReady = (event) => {
        // Player is ready, but keep it hidden until the button is clicked
    };

    const onPlayerStateChange = (event) => {
        if (event.data === window.YT.PlayerState.ENDED) {
            // setShowCode(true);
        }
    };

    return (
        <>
            <Nav />
            <div className="flex items-center justify-center w-full">
                <div id="videoContainer" className="relative w-full md:w-auto">
                    <div id="player" />
                    <div className="text-center px-2 mt-5 text-2xl flex items-center justify-center flex-col">
                        <span>CONGRATULATIONS!</span>
                        <span>You've been RICK ROLLED!</span>
                        <span>Here's our code for 10% off your next online order with us.</span>
                        <button className="uppercase font-light text-white bg-primary text-3xl py-4 w-full my-5 rounded-lg md:hover:rounded-3xl md:hover:bg-primary-hover md:transition-all md:duration-75 md:ease-linear" onClick={() => navigator.clipboard.writeText(secretCode).then(() => { setCopied(true); setTimeout(() => setCopied(false), 1000) })}>{copied ? 'Copied!' : `Code: ${secretCode}`}</button>
                        <a href='https://www.barbertonline.com.au/' target='_blank' rel='noreferrer' className='underline uppercase'>Order Online Now</a>
                    </div>
                    {showPlay && (
                        <div id="cover" className="flex items-start pt-12 md:items-center md:pt-0 justify-center absolute w-full h-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black text-white p-4 rounded">
                            <button className="text-3xl text-center uppercase font-light bg-white text-black py-3 w-full ml-2.5 rounded-lg md:hover:rounded-3xl md:hover:bg-gray-300 md:transition-all md:duration-75 md:ease-linear" onClick={() => { window.innerWidth <= 768 && document.getElementById("player").scrollIntoView({ behavior: 'smooth' }); playerRef.current.playVideo(); setShowPlay(false) }}>Reveal Your Code</button>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Rick;
