import { auth } from '../../firebase'
import { signInWithEmailAndPassword } from "@firebase/auth";

const Login = props => {
    return (
        <div className='fixed top-0 bottom-0 left-0 right-0 h-full w-full text-white'>
            <div className='z-30 fixed top-0 bottom-0 left-0 right-0 h-full w-full bg-black opacity-90'></div>
            <div className='flex items-center justify-center top-0 bottom-0 left-0 right-0 h-full w-full'>
                <div className='z-40 flex flex-col flex-grow justify-center items-center text-xl text-center'>
                    <button onClick={e => {
                        e.preventDefault()
                        props.setShowingLoginFloat(false)
                        props.enableBodyScroll(document.body)
                    }} className='font-light py-2.5 w-48 justify-center my-2.5 flex flex-row items-center space-x-2 md:hover:underline'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className="w-5 h-5 fill-white"><path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" /></svg>
                        <span className='uppercase'>Close</span>
                    </button>
                    <form onSubmit={e => {
                        e.preventDefault()
                        signInWithEmailAndPassword(auth, e.target.email.value, e.target.password.value).then(_ => {
                            props.setShowingLoginFloat(false)
                            props.enableBodyScroll(document.body)
                        }).catch(e => alert('Incorrect email or password, please try again.'))
                    }} className='text-center flex flex-col space-y-2 bg-white text-black rounded-xl p-4 mb-10'>
                        <span className='font-normal text-3xl uppercase'>Owner's Login</span>
                        <input name='email' className='text-center font-light py-2 w-[20rem]' type='email' placeholder='Email'></input>
                        <input name='password' className='text-center font-light py-2 w-[20rem]' type='password' placeholder='Password'></input>
                        <button className='text-white uppercase font-light bg-primary text-2xl py-2 w-full mb-5 rounded-lg md:hover:rounded-3xl md:hover:bg-primary-hover md:transition-all md:duration-75 md:ease-linear' type='submit'>Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login
