const Booker = props => {
    return (
        <div className='fixed top-0 bottom-0 left-0 right-0 h-full w-full text-white'>
            <div className='z-30 fixed top-0 bottom-0 left-0 right-0 h-full w-full bg-black opacity-90'></div>
            <div className='flex items-center justify-center top-0 bottom-0 left-0 right-0 h-full w-full'>
                <div className='z-40 flex flex-col flex-grow justify-center items-center text-xl text-center'>
                    <button onClick={e => {
                        e.preventDefault()
                        props.setShowingBookerFloat(false)
                        props.enableBodyScroll(document.body)
                    }} className='font-light py-2.5 w-48 justify-center my-2.5 flex flex-row items-center space-x-2 md:hover:underline'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className="w-5 h-5 fill-white"><path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" /></svg>
                        <span className='uppercase'>Close</span>
                    </button>
                    <iframe src="https://www.opentable.com.au/widget/reservation/canvas?rid=168770&amp;type=standard&amp;theme=standard&amp;overlay=false&amp;domain=comau&amp;lang=en-AU&amp;r3uid=viawhK2uKe&amp;newtab=false&amp;disablega=false&amp;ot_source=Restaurant%20website&amp;color=1" width="224" height="301" frameBorder="0" scrolling="no" name="opentable-make-reservation-widget" title="Online Reservations | OpenTable, Barbert Pizza Co. Elanora Heights"></iframe>
                    <span className='font-light mt-5'>For groups of 10 or more, guests must dine on the <a href='/setmenu' className='underline'>Set Menu</a>.</span>
                    <span className='font-light mt-5 mb-5'>For urgent enquiries, call <a href='tel:0299708775' target='_blank' rel='noreferrer' className='underline'>(02) 9970 8775</a>.</span>
                    <span className='font-regular mb-10'>Looking to order <a className='underline' href='https://www.barbertonline.com.au/' target='_blank' rel='noreferrer'>pickup</a> or <a className='underline' href='https://www.ubereats.com/au/store/barbert-pizza-co/eR4IQAiNTJ6CsBdtVIGI5A' target='_blank' rel='noreferrer'>delivery</a>?</span>
                </div>
            </div>
        </div>
    )
}

export default Booker
