import { useState } from 'react'
import { useAuthState } from "react-firebase-hooks/auth";
import { signOut } from "@firebase/auth";
import { auth } from '../../firebase'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Booker from '../Booker'
import Login from '../Login'

const Nav = () => {
    const [user] = useAuthState(auth);
    const [clickedCount, setClickedCount] = useState(0)
    const [showingBookerFloat, setShowingBookerFloat] = useState(false)
    const [showingLoginFloat, setShowingLoginFloat] = useState(false)
    return (
        <div className='md:sticky top-[-110px] z-50 flex flex-col pt-5 text-center'>
            <div className='flex flex-col items-center bg-white md:pb-5'>
                <h1 className='font-title text-6xl'>Barbert Pizza Co.</h1>
                <h2 onClick={e => {
                    e.preventDefault()
                    if (user) return
                    if (clickedCount === 2) {
                        setClickedCount(0)
                        setShowingLoginFloat(true)
                        disableBodyScroll(document.body)
                    } else {
                        setClickedCount(clickedCount + 1)
                    }
                }} className='font-sans text-2xl uppercase'>Elanora Heights</h2>
                <div className='flex flex-col md:flex-row items-center justify-center md:space-x-4 w-full font-light uppercase text-2xl mt-2.5 md:mt-5'>
                    <a href='/' className='md:hover:underline py-2.5 md:py-0 w-full md:w-auto'>Home</a>
                    <span className='text-center hidden md:block'>•</span>
                    <a href='/menu' className='md:hover:underline py-2.5 md:py-0 w-full md:w-auto'>Food & Drink Menu</a>
                    <span className='text-center hidden md:block'>•</span>
                    <a href='/setmenu' className='md:hover:underline py-2.5 md:py-0 w-full md:w-auto'>Set Menu</a>
                    <span className='text-center hidden md:block'>•</span>
                    <a href='/kidsclass' className='md:hover:underline py-2.5 md:py-0 w-full md:w-auto'>Kids Class</a>
                    <span className='text-center hidden md:block'>•</span>
                    <button onClick={
                        e => {
                            e.preventDefault()
                            setShowingBookerFloat(true)
                            disableBodyScroll(document.body)
                        }
                    } className='md:hover:underline uppercase py-2.5 md:py-0 w-full md:w-auto font-regular'>Book a Table</button>
                </div>
                {user &&
                    <div className='flex flex-row space-x-2 mt-5 mb-2.5 md:mb-0'><span>Logged in as {user.email}</span><span>•</span><button onClick={e => {
                        e.preventDefault()
                        signOut(auth)
                        // window.location.reload()
                    }} className='hover:underline'>Log Out</button></div>
                }
            </div>
            <div className='fade h-5'></div>
            <div className={showingBookerFloat ? '' : 'hidden'}>
                <Booker showingBookerFloat={showingBookerFloat} setShowingBookerFloat={setShowingBookerFloat} enableBodyScroll={enableBodyScroll} />
            </div>
            <div className={showingLoginFloat ? '' : 'hidden'}>
                <Login showingLoginFloat={showingLoginFloat} setShowingLoginFloat={setShowingLoginFloat} enableBodyScroll={enableBodyScroll} />
            </div>
        </div>
    )
}

export default Nav