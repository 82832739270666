import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "@firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyDrG8Q6dbFQa53AQVMiG7zgqNxw0FqVZrU",
  authDomain: "toro-nero.firebaseapp.com",
  projectId: "toro-nero",
  storageBucket: "toro-nero.appspot.com",
  messagingSenderId: "473205893958",
  appId: "1:473205893958:web:20681ea0d4a210e48ad347"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const firestore = getFirestore(app);