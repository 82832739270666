import { useState, useEffect } from 'react'
import Footer from '../../components/Footer'
import Nav from '../../components/Nav'
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, firestore } from '../../firebase'
import BlockUi from 'react-block-ui'
import { query, orderBy, collection, doc, getDoc, getDocs, updateDoc, deleteDoc, addDoc } from '@firebase/firestore'

const KidsClass = () => {
    document.title = 'Kids Class - Barbert Pizza Co.';
    let editPromises = []
    const emailBody = 'We%20will%20require%20some%20information%20to%20secure%20your%20child%E2%80%99s%20spot%20for%20the%20lesson.%0APlease%20fill%20out%20the%20below%20form%2C%20and%20then%20click%20send.%0A%0A------------%0A%0AChild%E2%80%99s%20Name%3A%20%0AChild%E2%80%99s%20Age%3A%20%0AChild%E2%80%99s%20usual%20topping%20preference%3A%20%0AChild%E2%80%99s%20usual%20juice%20preference%3A%20%0AAdditional%20information%20about%20child%20%28optional%29%3A%20%0A%0ATo%20add%20other%20children%2C%20please%20copy-paste%20the%20above%20template%20for%20each%20child.%0A%0AParent%2Fguardian%20name%3A%20%0AMobile%20number%3A%20%0A%0A------------%0A%0AIf%20you%20have%20any%20further%20questions%20please%20write%20below%20and%20we%20will%20get%20back%20to%20you.%0A%0AWe%20look%20forward%20to%20seeing%20you%21%0APlease%20now%20click%20send.'
    const [user] = useAuthState(auth);
    const [isLoading, setIsLoading] = useState(false)
    const [reload, triggerReload] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [desc, setDesc] = useState("")
    const [dates, setDates] = useState([])
    const [newDate, setNewDate] = useState("")

    useEffect(() => {
        setIsLoading(true)
        const infoDocRef = doc(firestore, 'kidsClasses', 'info')
        getDoc(infoDocRef).then(doc => {
            const data = doc.data()
            setDesc(data.description)
            const q = query(collection(infoDocRef, "dates"), orderBy("date", "asc"))
            let theDates = []
            getDocs(q).then(dates => {
                dates.docs.forEach(date => {
                    let d = date.data();
                    const dateObj = new Date(d.date);
                    const twoDaysAgo = new Date();
                    twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
                    if (dateObj < twoDaysAgo) {
                        // Don't display old classes.
                        // If admin is logged in, remove the old class from the DB.
                        // if (user) {
                        //     deleteDoc(date.ref)
                        // }
                        return
                    }
                    d.id = date.id;
                    const regex = /^(\d{4})-(\d{2})-(\d{2})$/;
                    const match = regex.exec(d.date);
                    const year = match[1].slice(-2);
                    const month = match[2];
                    const day = match[3];
                    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
                    const dayOfWeekName = daysOfWeek[dateObj.getDay()];
                    d.dateString = dayOfWeekName + ' ' + day + '/' + month + '/' + year;
                    d.dateStringSimple = day + '/' + month + '/' + year;
                    theDates.push(d)
                })
                setDates(theDates)
                setIsLoading(false)
            })
        })
    }, [reload]);

    function padZero(num) {
        return num < 10 ? '0' + Number(num) : Number(num);
    }

    return (
        <>
            <Nav />
            <div className='flex items-center justify-center'>
                <div className='flex flex-col items-center text-center mx-5 md:w-[40rem]'>
                    {user && !editMode &&
                        <div className='flex w-full justify-center mb-8'>
                            <button onClick={e => {
                                e.preventDefault()
                                setEditMode(true)
                            }} className='flex flex-row items-center space-x-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                </svg><span>Edit</span></button>
                        </div>
                    }
                    {user && editMode &&
                        <div className='flex w-full justify-center mb-8'>
                            <button onClick={e => {
                                e.preventDefault()
                                setIsLoading(true)
                                Promise.all(editPromises).then(_ => {
                                    setEditMode(false)
                                    triggerReload(!reload)
                                })
                            }} className='flex flex-row items-center space-x-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                </svg><span>Finish Editing</span></button>
                        </div>
                    }
                    {user && editMode ?
                        <BlockUi tag='div' className='w-full' blocking={isLoading}>
                            <div className='flex flex-col items-center justify-center text-center'>
                                <div className='font-light text-2xl mb-2 flex flex-col w-full'>
                                    <textarea onBlur={e => {
                                        e.preventDefault()
                                        const ref = doc(firestore, 'kidsClasses', 'info')
                                        editPromises.push(updateDoc(ref, { description: e.target.value.replaceAll('\n', '\\n') }))
                                    }} className="text-center h-96 w-full focus:outline-0" type='text' placeholder='Kids class description' key={`${Math.floor((Math.random() * 1000000))}-min`} defaultValue={desc && desc.replaceAll('\\n', '\n')}></textarea>
                                </div>
                                <h2 className='font-noto uppercase text-4xl mb-8 mt-8'>Upcoming Classes</h2>
                                <div className='flex flex-col space-y-2 font-light text-2xl'>
                                    {dates.map(d => {
                                        return (
                                            <div key={`${Math.floor((Math.random() * 1000000))}-min`} className='flex flex-row space-x-2 items-center bg-gray-100 py-4 px-4 rounded-full'>
                                                <input onBlur={e => {
                                                    e.preventDefault()
                                                    const regex = /^(\d{1,2})\/(\d{1,2})\/(\d{2})$/;
                                                    const match = regex.exec(e.target.value);
                                                    if (!match) {
                                                        alert('Date must be in the format DD/MM/YY.')
                                                        e.target.value = d.dateStringSimple
                                                        return
                                                    }
                                                    const year = '20' + match[3];
                                                    const month = padZero(match[2]);
                                                    const day = padZero(match[1]);
                                                    const formattedDate = year + '-' + month + '-' + day;
                                                    const infoDocRef = doc(firestore, 'kidsClasses', 'info')
                                                    const dateDocRef = doc(infoDocRef, 'dates', d.id)
                                                    editPromises.push(updateDoc(dateDocRef, { date: formattedDate }))
                                                }} className='w-32 text-center focus:outline-0 bg-inherit' type='text' placeholder='DD/MM/YY' defaultValue={d.dateStringSimple}></input>
                                                <button onClick={(e => {
                                                    e.preventDefault()
                                                    setIsLoading(true)
                                                    const infoDocRef = doc(firestore, 'kidsClasses', 'info')
                                                    const dateDocRef = doc(infoDocRef, 'dates', d.id)
                                                    updateDoc(dateDocRef, { full: !d.full }).then(_ => triggerReload(!reload))
                                                })} className={d.full ? 'w-32 bg-[#da3743] md:hover:bg-[#b32d36] text-white py-2 px-4 rounded-3xl md:hover:rounded-3xl md:transition-all md:duration-75 md:ease-linear' : 'w-32 bg-primary md:hover:bg-primary-hover text-white py-2 px-4 rounded-3xl md:hover:rounded-3xl md:transition-all md:duration-75 md:ease-linear'}>{d.full ? 'Full' : 'Not Full'}</button>
                                                <button onClick={(e => {
                                                    e.preventDefault()
                                                    setIsLoading(true)
                                                    const infoDocRef = doc(firestore, 'kidsClasses', 'info')
                                                    const dateDocRef = doc(infoDocRef, 'dates', d.id)
                                                    deleteDoc(dateDocRef).then(_ => triggerReload(!reload))
                                                })} className='w-12 bg-[#da3743] md:hover:bg-[#b32d36] text-white py-4 px-4 rounded-3xl md:hover:rounded-3xl md:transition-all md:duration-75 md:ease-linear'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                    </svg></button>
                                                {/* <a href='' target='_blank' rel='noreferrer' className='w-24 text-2xl text-center font-light bg-primary text-white py-2 px-4 rounded-3xl md:hover:rounded-3xl md:hover:bg-primary-hover md:transition-all md:duration-75 md:ease-linear'>Book</a> */}
                                            </div>
                                        )
                                    })}
                                    <span>Add a new class:</span>
                                    <div className='flex flex-row space-x-2 items-center bg-gray-100 py-4 px-4 rounded-full'>
                                        <input onChange={(e => {
                                            setNewDate(e.target.value)
                                        })} className='w-52 text-center focus:outline-0 bg-inherit' type='text' placeholder='DD/MM/YY'></input>
                                        <button onClick={(e => {
                                            e.preventDefault()
                                            const regex = /^(\d{1,2})\/(\d{1,2})\/(\d{2})$/;
                                            const match = regex.exec(newDate);
                                            if (!match) {
                                                alert('Date must be in the format DD/MM/YY.')
                                                return
                                            }
                                            const year = '20' + match[3];
                                            const month = padZero(match[2]);
                                            const day = padZero(match[1]);
                                            const formattedDate = year + '-' + month + '-' + day;
                                            const infoDocRef = doc(firestore, 'kidsClasses', 'info')
                                            const dateCollRef = collection(infoDocRef, 'dates')
                                            addDoc(dateCollRef, { date: formattedDate, full: false }, { merge: true }).then(_ => {
                                                triggerReload(!reload)
                                            })
                                        })} className='w-24 bg-primary md:hover:bg-primary-hover text-white py-2 px-4 rounded-3xl md:hover:rounded-3xl md:transition-all md:duration-75 md:ease-linear'>Add</button>
                                    </div>
                                </div>
                            </div>
                        </BlockUi>
                        :
                        isLoading ?
                            <BlockUi tag='div' blocking={isLoading}>
                                <div className='flex flex-col items-center justify-center text-center'>
                                    {/* <h2 className='font-noto uppercase text-4xl mb-8'>Kids Class</h2> */}
                                    <div className='font-light text-2xl mb-2 flex flex-col'>
                                        <span>Loading...</span>
                                    </div>
                                    <h2 className='font-noto uppercase text-4xl mb-8 mt-8'>Upcoming Classes</h2>
                                    <div className='flex flex-col space-y-2 font-light text-2xl'>
                                        <div className='flex flex-row space-x-5 items-center bg-gray-100 py-4 px-4 rounded-full'>
                                            <span className='w-52'>Loading...</span>
                                            <button className='w-24 text-2xl text-center font-light bg-primary text-white py-2 px-4 rounded-3xl md:hover:rounded-3xl md:hover:bg-primary-hover md:transition-all md:duration-75 md:ease-linear'>Book</button>
                                        </div>
                                        <div className='flex flex-row space-x-5 items-center bg-gray-100 py-4 px-4 rounded-full'>
                                            <span className='w-52'>Loading...</span>
                                            <button className='w-24 text-2xl text-center font-light bg-primary text-white py-2 px-4 rounded-3xl md:hover:rounded-3xl md:hover:bg-primary-hover md:transition-all md:duration-75 md:ease-linear'>Book</button>
                                        </div>
                                        <div className='flex flex-row space-x-5 items-center bg-gray-100 py-4 px-4 rounded-full'>
                                            <span className='w-52'>Loading...</span>
                                            <button className='w-24 text-2xl text-center font-light bg-primary text-white py-2 px-4 rounded-3xl md:hover:rounded-3xl md:hover:bg-primary-hover md:transition-all md:duration-75 md:ease-linear'>Book</button>
                                        </div>
                                    </div>
                                </div>
                            </BlockUi>
                            :
                            <div className='flex flex-col items-center justify-center text-center'>
                                <div className='font-light text-2xl mb-2 flex flex-col'>
                                    {desc && desc.split('\\n').map(b => {
                                        return (
                                            <div key={`${Math.floor((Math.random() * 1000000))}-min`}>
                                                {b === '' ?
                                                    <br />
                                                    :
                                                    <span>{b}</span>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                                <h2 className='font-noto uppercase text-4xl mb-8 mt-8'>Upcoming Classes</h2>
                                <div className='flex flex-col space-y-2 font-light text-2xl'>
                                    {!dates.length ? <span>No upcoming classes</span> : dates.map(d => {
                                        return (
                                            <div key={`${Math.floor((Math.random() * 1000000))}-min`} className='flex flex-row space-x-5 items-center bg-gray-100 py-4 px-4 rounded-full'>
                                                <span className='w-52'>{d.dateString}</span>
                                                {d.full ?
                                                    <button disabled className='cursor-not-allowed w-24 text-2xl text-center font-light bg-[#da3743] text-white py-2 px-4 rounded-3xl'>Full</button>
                                                    :
                                                    <a href={'mailto:blincartptyltd@gmail.com?subject=' + d.dateStringSimple + '%20Kids%20Pizza%20Class&body=' + emailBody} target='_blank' rel='noreferrer' className='w-24 text-2xl text-center font-light bg-primary text-white py-2 px-4 rounded-3xl md:hover:rounded-3xl md:hover:bg-primary-hover md:transition-all md:duration-75 md:ease-linear'>Book</a>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                    }
                    {user && !editMode &&
                        <div className='flex w-full justify-center mt-8'>
                            <button onClick={e => {
                                e.preventDefault()
                                setEditMode(true)
                            }} className='flex flex-row items-center space-x-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                </svg><span>Edit</span></button>
                        </div>
                    }
                    {user && editMode &&
                        <div className='flex w-full justify-center mt-8'>
                            <button onClick={e => {
                                e.preventDefault()
                                setIsLoading(true)
                                Promise.all(editPromises).then(_ => {
                                    setEditMode(false)
                                    triggerReload(!reload)
                                })
                            }} className='flex flex-row items-center space-x-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                </svg><span>Finish Editing</span></button>
                        </div>
                    }
                </div>
            </div>
            <Footer />
        </>
    )
}
export default KidsClass
